@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    font-family: "Noto Sans TC", sans-serif;
  }
}

@keyframes bounce {
  70% {
    transform: translateX(0%);
  }
  80% {
    transform: translateX(-25%);
  }
  90% {
    transform: translateX(0%);
  }
  95% {
    transform: translateX(-7%);
  }
  100% {
    transform: translateX(0);
  }
}

.bounce {
  animation: bounce 1s ease infinite;
}
